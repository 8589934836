import React from "react";
import f1 from "../../Assets/hyundai/h-0.jpg";
import f2 from "../../Assets/hyundai/h-1.jpg";
import f3 from "../../Assets/hyundai/h-2.jpg";
import f4 from "../../Assets/hyundai/h-3.jpg";
import { BlockImg, BlockProjTitle } from "../../component/Block";


function hyundai() {
  return (
    <div>
          <BlockProjTitle
      Project={"Hyundai"}
      Role={"UI Designer"}
      Deli1={"UI Moodboard"}
      Deli2={""}
      Deli3={""}
      En={"Hyundai "}
      Jp={"Hyundai "}
    />
      <BlockImg image={f1} />
      <BlockImg image={f2} />
      <BlockImg image={f3} />
      <BlockImg image={f4} />
    </div>
  );
}

export default hyundai;
