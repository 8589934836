import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import PasswordPopup from './PasswordPopup'; // Import PasswordPopup

const PasswordProtectedRoute = ({ path, element, password, ...rest }) => {
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  const handlePasswordSubmit = (enteredPassword) => {
    if (enteredPassword === password) {
      setIsPasswordCorrect(true);
    } else {
      alert('Incorrect password.:-D');
    }
  };

  return (
    <Route
      {...rest}
      element={
        isPasswordCorrect ? (
          element
        ) : (
          <div>
            <PasswordPopup onSubmit={handlePasswordSubmit} password={password} />
          </div>
        )
      }
    />
  );
};

export default PasswordProtectedRoute;