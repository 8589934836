import React from "react";
import { BlockImg, BlockProjTitle } from "../../component/Block";
import { Container } from "../../component/Core";
import f1 from "../../Assets/shopify/10.1.jpg";
import f2 from "../../Assets/shopify/10.2.jpg";
import f3 from "../../Assets/shopify/10.3.jpg";
import f4 from "../../Assets/shopify/10.4.jpg";
import f5 from "../../Assets/shopify/10.5.jpg";


function Shopify() {

  const ImgCanooB = [
    f1,
    f2,
    f3,
    f4,
    f5,
  ]

  return (
    <Container width={[1]} flexDirection="column" flexWrap="wrap" alignItems="center">
       <BlockProjTitle
      Project={"Shopify Case Study"}
      Role={"-"}
      Deli1={"-"}
      En={"Unused Design"}
      Jp={"-"}
    />
    
      <BlockImg image={f1} />
      <BlockImg image={f2} />
      <BlockImg image={f3} />
      <BlockImg image={f4} />
      <BlockImg image={f5} />
    </Container>
  );
}

export default Shopify;
