import React, { createContext, useState } from 'react';

export const PasswordContext = createContext({
  isPasswordProtected: false,
  setIsPasswordProtected: () => {},
});

const PasswordProvider = ({ children }) => {
  const [isPasswordProtected, setIsPasswordProtected] = useState(true);

  return (
    <PasswordContext.Provider value={{ isPasswordProtected, setIsPasswordProtected }}>
      {children}
    </PasswordContext.Provider>
  );
};

export default PasswordProvider;