import React from "react";
import lo1 from "../Assets/about/about-1.jpg";

import { Container } from "../component/Core";
import { base } from "../theme";
import { ThemeProvider } from "styled-components";
import { Block2Col, Block4Col, BlockImg } from "../component/Block";

function About() {

  return (
    <ThemeProvider theme={base}>
      <Container width={[1]} display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left">
        
        <Block2Col pb={[4]} size={[3]} gap={[1]} 
          col1a={'Los Angeles and remote, '}
          col1b={"for new projects and enquiries."}
          col1c={"tszhoip@gmail.com"}
        />

        <Block2Col pb={[4]} size={[2]} gap={[3]} line={"1px #d5d5d5 solid"}
          col2a={"My expertise extends beyond aesthetics to the creation of robust design systems that seamlessly integrate across various media. Whether it's a rapid branding initiative or a deeply emotive storytelling campaign, I possess the versatility to tailor my approach to meet the unique needs of each project."}
          col2b={"Inspired by the vibrant and eclectic spirit of Los Angeles, I embrace the unconventional and the unexpected. My design philosophy centers around observation and empathy, using visual language as a powerful tool to connect with audiences on a profound level."}
          col2c={"Proficient in Figma and a range of web development tools, I bring a hands-on approach to my work. My ability to seamlessly navigate between design and development ensures that my concepts translate into tangible, functional experiences."}
          col2d={"Throughout my career, I've had the privilege of collaborating with industry-leading brands, including [Canoo, Figs, etc.]. These partnerships have allowed me to contribute to the creation of innovative and memorable products and experiences."}
        />

        <Block4Col pb={[1]} size={[2]} 
          col1a={"Full Time Experience"}
        />

        <Block4Col pb={[4]} size={[2]} gap={[1]} line={"1px #d5d5d5 solid"}
          col1a={"Current"}
          col1b={'Digital Product Manager'}
          col1c={'Canoo Inc.'}
          col2a={"17–18"}
          col2b={"Web Designer"}
          col2c={"Figs"}
          col3a={"15–16"}
          col3b={"Lead Design Manager"}
          col3c={"Zipedtech"}
          col4a={"13–15"}
          col4b={"Sr. Graphic Design"}
          col4c={"American Apparel"}
        />

        <Block4Col pb={[1]} size={[2]}
          col1a={"Contract Experience"}
        />

        <Block4Col pb={[4]} size={[2]} gap={[1]} line={"1px #d5d5d5 solid"}
          col1a={"LuLuLemon"}
          col1b={'Nike'}
          col1c={'Media Art Lab'}
          col2a={"Ares"}
          col2b={"Hyundai"}
          col2c={"VW"}
          col3a={"Accenture"}
          col3b={"Microsoft"}
          col3c={"Apple Music"}
        />


        <BlockImg image={lo1} />








      </Container>
    </ThemeProvider>
  );
}

export default About;
