import React from "react";
import { BlockImg, BlockProjTitle } from "../../component/Block";
import { Container } from "../../component/Core";
import ca1 from "../../Assets/inn/9.01.jpg";
import ca2 from "../../Assets/inn/9.02.jpg";
import ca3 from "../../Assets/inn/9.03.jpg";
import ca4 from "../../Assets/inn/9.04.jpg";
import ca5 from "../../Assets/inn/9.05.jpg";
import ca6 from "../../Assets/inn/9.06.jpg";
import ca7 from "../../Assets/inn/9.07.jpg";
import ca8 from "../../Assets/inn/9.08.jpg";


function inno() {


  return (
    <Container width={[1]} flexDirection="column" flexWrap="wrap" alignItems="center">
       <BlockProjTitle
        Project={"Innocell Science Park"}
        Role={"Consultance"}
        Deli1={"App Art Direction, Visual Identity, Web"}
        En={"Innocell is a hostal slash coworking space that located right next to the university. The Hotel is embrassing tech forward experience that anything can be controled through an app. The art direction was nomad style with neumorphism touch."}
        Jp={"Innocell 是一个位于大学旁边的旅舍斜线联合办公空间，该酒店提供先进的技术体验，一切都可以通过应用程序进行控制。"}
      />
      <BlockImg image={ca1} />
      <BlockImg image={ca2} />
      <BlockImg image={ca3} />
      <BlockImg image={ca4} />
      <BlockImg image={ca5} />
      <BlockImg image={ca6} />
      <BlockImg image={ca7} />
      <BlockImg image={ca8} />
    </Container>
  );
}

export default inno;
