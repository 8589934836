import React from "react";
import { BlockImg, BlockProjTitle } from "../../component/Block";
import { Container } from "../../component/Core";
import f1 from "../../Assets/nike/5.01.jpg";
import f2 from "../../Assets/nike/5.02.jpg";
import f3 from "../../Assets/nike/5.03.jpg";
import f4 from "../../Assets/nike/5.04.jpg";
import f5 from "../../Assets/nike/5.05.jpg";



function Nike() {



  return (
    <Container width={[1]} flexDirection="column" flexWrap="wrap" alignItems="center">
      <BlockProjTitle
        Project={"Nike"}
        Role={"Design Lead"}
        Deli1={"Art Direction, environmental Graphic"}
        En={"We partnered with the studio, supermorebetter on a pitch for a nike campaign, translating the 2D elements into 3D fixtures and graphics for the spatial design of Nike SoHo, NY."}
        Jp={"我们与超级morebetter工作室合作，为耐克推出了一款产品，将二维元素转化为三维装置和图形，用于耐克纽约SoHo的空间设计。"}
      />
      
      <BlockImg image={f1} />
      <BlockImg image={f2} />
      <BlockImg image={f3} />
      <BlockImg image={f4} />
      <BlockImg image={f5} />
    </Container>
  );
}

export default Nike;
