import React from "react";
import { BlockThumbS } from "../component/Block";
import { Container } from "../component/Core";
// import logo from "../Assets/logo/Llogo.jpg";
import nike from "../Assets/nike/LNike.jpg";
import inno from "../Assets/inn/LInno.jpg";
import shop from "../Assets/shopify/LShop.jpg";
import figs from "../Assets/figs/LFigs.jpg";
import CanooB from "../Assets/CanooB/LCanooB.jpg";
import CanooW from "../Assets/CanooW/LCanooW.jpg";
import Process from "../Assets/Process/LProcess.jpg";
import treedom from "../Assets/treedom/LTree.jpg";
import hyundai from "../Assets/hyundai/LHyundai.jpg";
import CanooDS from "../Assets/CanooDS/LCanooDS.jpg";
import CanooHMI from "../Assets/CanooHMI/LCanooHMI.jpg";
import Nike from "../pages/work/Nike";

export const OtherProj = (props) => {


  return (
    <Container width={"100%"} alignItems="left" display="flex" flexDirection="row" overflowX="scroll" flexWrap="wrap">
         <BlockThumbS
        imag={CanooB}
        title="Canoo"
        description="Branding"
        to="/work/CanooB"
        bgColor="lightgrey"
        tColor="black"
        bgColorHover="white"
        tColorHover="black"
      />
         <BlockThumbS
        imag={nike}
        title="Nike"
        description=" "
        to="/work/nike"
        bgColor="lightgrey"
        tColor="black"
        bgColorHover="white"
        tColorHover="black"
      />
       <BlockThumbS
        imag={inno}
        title="Innocell"
        description=" "
        to="/work/inno"
        bgColor="lightgrey"
        tColor="black"
        bgColorHover="white"
        tColorHover="black"
      />
       <BlockThumbS
        imag={CanooW}
        title="Canoo"
        description="showroom"
        to="/work/CanooW"
        bgColor="lightgrey"
        tColor="black"
        bgColorHover="white"
        tColorHover="black"
      />

      </Container>
  );
};
