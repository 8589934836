import React from "react";
import f1 from "../../Assets/treedom/f-1.jpg";
import f2 from "../../Assets/treedom/f-2.jpg";
import f3 from "../../Assets/treedom/f-3.jpg";
import f4 from "../../Assets/treedom/f-4.jpg";
import f5 from "../../Assets/treedom/f-5.jpg";
import { BlockImg, BlockProjTitle } from "../../component/Block";

function treedom() {
  return (
    <div>
          <BlockProjTitle
      Project={"Treedom App"}
      Role={"UI Designer"}
      Deli1={"Logo, Product"}
      Deli2={""}
      Deli3={""}
      En={"Treedom is a startup app that offer community to do good by giving back by donation, volunteer and groups."}
      Jp={"Treedom 是一款初创应用程序，通过捐赠、志愿者和团体的方式回馈社区，帮助社区做好事。"}
    />
      <BlockImg image={f1} />
      <BlockImg image={f2} />
      <BlockImg image={f3} />
      <BlockImg image={f4} />
      <BlockImg image={f5} />
    </div>
  );
}

export default treedom;
