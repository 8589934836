import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "styled-components";
import { base } from "./theme";
import "../src/Assets/font/AirPro.ttf";
import "../src/Assets/font/NewFont-Regular.ttf";
import "../src/Assets/font/N1MONO-MONO.ttf";

import 'firebase/analytics';
import 'firebase/compat/analytics';

import PasswordProvider from './component/PasswordContext';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={base}>
      <PasswordProvider>
        <App />
      </PasswordProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);