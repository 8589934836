import React, { useState, useContext } from 'react';
import { PasswordContext } from './PasswordContext';

import { Container } from "../component/Core";
import { OtherProj } from "./OtherProj";


const PasswordPopup = () => {
  const [password, setPassword] = useState('');
  const { setIsPasswordProtected } = useContext(PasswordContext);
  const [isFocused, setIsFocused] = useState(false);
//   const FormInput = styled.input`
//   background-color grey;
//   box-shadow: 0px 0px 1px ${(props) => props.theme.colors.gre20} inset;
//   margin-right: 8px;
//   border-radius: 6px;
//   box-shadow: inset 0px 0px 0px 0px red;
//       border: 0;
//     padding: 0;
// `



  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === '888') {
      setIsPasswordProtected(false);
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <>
    <Container width={[1]} height={"100vh"} display="flex" flexDirection="column" flexWrap="wrap" justifyContent="center" alignItems="center">
      <form onSubmit={handleSubmit}>
        <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter Password" 
          style={{backgroundColor:"#f5f5f5", padding: "16px", 
            border: isFocused ? "none" : "none" , 
            outline:isFocused ? "none" : "none" ,
            boxShadow: isFocused ? "0px 0px 12px grey inset" : "0px 0px 0px blue inset" }} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}
        />
        <button type="submit" style={{padding: "16px", border: "none", backgroundColor: "black", color: "white" }}>Submit</button>
      </form>
     
 
   
    </Container>
    <Container width={[1]} height={"100vh"} display="flex" flexDirection="column" flexWrap="wrap" justifyContent="center" alignItems="center">
    </Container>
    <OtherProj/>
    </>
  );
};

export default PasswordPopup;