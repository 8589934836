import React from "react";
import f1 from "../../Assets/figs/f-1.jpg";
import f2 from "../../Assets/figs/f-2.jpg";
import f3 from "../../Assets/figs/f-3.jpg";
import f4 from "../../Assets/figs/f-4.jpg";
import { BlockImg, BlockProjTitle } from "../../component/Block";
import { Container } from "../../component/Core";


function Figs() {
  return (
    <Container width={[1]} flexDirection="column" flexWrap="wrap" alignItems="center">
      <BlockProjTitle
      Project={"Figs E-commerce"}
      Role={"Web Designer"}
      Deli1={"Shopify shopping experience"}
      Deli2={""}
      Deli3={""}
      En={"Figs, a rising ecommerce brand in 2019, sought to redefine its brand identity to align with its growing success. The initial branding, while effective, lacked a modern aesthetic that could resonate with a broader audience. The challenge was to introduce a more contemporary and fashionable approach to the traditional scrubwear sector, proving that scrubs could be both functional and stylish."}
      Jp={"Figs 是一个在 2019 年崛起的电商品牌，寻求重新定义其品牌形象以适应不断增长的成功。最初的品牌虽然有效，但缺乏现代美学，无法与更广泛的受众产生共鸣。挑战在于向传统的磨砂服装领域引入更现代和时尚的外观，证明磨砂服装既可以实用又可以时尚。"}
    />
      <BlockImg image={f1} />
      <BlockImg image={f2} />
      <BlockImg image={f3} />
      <BlockImg image={f4} />

      
    </Container>
  );
}

export default Figs;
